import { useEffect, useState } from "react";
import "../Styles/Achievements.css";
import { CountUp } from 'countup.js';
import { baseUrl } from '../config';

const OurAchievements = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`${baseUrl}complex-structures/1`)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const animateValues = () => {
      const value1 = new CountUp('value1', data.value_1);
      const value2 = new CountUp('value2', data.value_2);
      const value3 = new CountUp('value3', data.value_3);

      if (!value1.error && !value2.error && !value3.error) {
        value1.start();
        value2.start();
        value3.start();
      } else {
        console.error('CountUp error');
      }
    };

    if (data) {
      animateValues();
    }
  }, [data]);

  return (
    <div className="achievements-container py-5">
      <div className="container-xl d-flex">
        <div className="achivments-container">
          <h3 className="text-center text-wrap" data-aos="fade-down">
            {data.big_title}
          </h3>
          <div className="achivments">
            <div className="our-vision my-3" data-aos="fade-right" data-aos-delay="100">
              <h5 className="fw-bold">{data.secondary_title_1}</h5>
              <ul>
                <li className="fw-bold text-wrap">- {data.body_1}</li>
              </ul>
            </div>
            <div className="our-vision my-3" data-aos="fade-right" data-aos-delay="200">
              <h5 className="fw-bold">{data.secondary_title_2}</h5>
              <ul>
                <li className="fw-bold text-wrap">- {data.body_2}</li>
              </ul>
            </div>
            <div className="our-vision my-3" data-aos="fade-right" data-aos-delay="300">
              <h5 className="fw-bold">{data.secondary_title_3}</h5>
              <ul>
                <li className="fw-bold text-wrap">- {data.body_3}</li>
              </ul>
            </div>
          </div>
          <div className="achivments-number flex-wrap d-flex align-items-center mt-5">
            <div data-aos="zoom-in-up" data-aos-delay="100" className="fw-bold d-flex align-items-center flex-column justify-content-center fs-2">
              <h2 id="value1" className="fs-1 fw-bold">0</h2>
              <p className="text-center">{data.value_1_text}</p>
            </div>
            <div data-aos="zoom-in-up" data-aos-delay="200" className="fw-bold d-flex align-items-center flex-column justify-content-center fs-2">
              <h2 id="value2" className="fs-1 fw-bold">0</h2>
              <p className="text-center">{data.value_2_text}</p>
            </div>
            <div data-aos="zoom-in-up" data-aos-delay="300" className="fw-bold d-flex align-items-center flex-column justify-content-center fs-2">
              <h2 id="value3" className="fs-1 fw-bold">0</h2>
              <p className="text-center">{data.value_3_text}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurAchievements;
