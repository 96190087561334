import { useEffect, useState } from "react";
import "../Styles/OurPartners.css";
import { baseUrl } from "../config";
const OurPartners = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(`${baseUrl}partners`)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  }, []);
  return (
    <>
      <div className="partners-container">
        <div className="container-xl">
          <h6 className="fs-6 text-center py-5">OUR PARTNERS</h6>
        </div>
        <div className="scroll text d-flex ">
          <div className="d-flex">
            {data &&
              data.map((ele) => {
                return (
                  <h1 key={ele.id} className="display-5">
                    {ele.name} •
                  </h1>
                );
              })}
          </div>
          <div className="d-flex">
            {data &&
              data.map((ele) => {
                return (
                  <h1 key={ele.id} className="display-5">
                    {ele.name} •
                  </h1>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurPartners;
