import Header from "../Comp/Header";
import HomeSlider from "../Comp/HomeSlider";
import Welcome from "../Comp/BlogDetails";
import "../Styles/Home.css";
import NewArrivals from "../Comp/NewArrivals";
import Reviews from "../Comp/Reviews";
import ProfilesComments from "../Comp/ProfilesComments";
import InstagramPost from "../Comp/InstagramPost";
import OurAchievements from "../Comp/OurAchievements";
import OurPartners from "../Comp/OurPartners";
import OurCars from "../Comp/Ourcars";
import BlogPost from "../Comp/BlogPosts";
import Footer from "../Comp/Footer";

const Home = () => {
  return (
    <>
      <div className="home">
        <Header />
        <HomeSlider />
      </div>
      <Welcome />
      <NewArrivals />
      <Reviews />
      <ProfilesComments />
      <InstagramPost />
      <OurAchievements />
      <OurPartners />
      <OurCars />
      <BlogPost />
      <Footer />
    </>
  );
};

export default Home;
