import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../Styles/InstagramPost.css";
import { baseUrl } from "../config"; // Make sure you have this base URL correctly defined

const InstagramPost = () => {
  const [instagramPosts, setInstagramPosts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchInstagramPosts();
  }, []);

  const fetchInstagramPosts = () => {
    axios.get(`${baseUrl}instagrams`)
      .then(response => {
        setInstagramPosts(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the Instagram posts!", error);
        setError('Failed to fetch Instagram posts. Please try again later.');
      });
  };

  return (
    <>
      <div className="instagram-container py-5">
        <div className="container-xl">
          <h3 className="text-center fs-1" data-aos="fade-down-right">
            Instagram
          </h3>
          <div className="instagram-posts flex-wrap d-flex align-items-center px-5 mt-4">
            {error && <p className="error-message">{error}</p>}
            {instagramPosts.map((post, index) => (
              <a
                key={post.id}
                href={post.link}
                target="_blank"
                rel="noopener noreferrer"
                className="d-block post"
                data-aos="zoom-out"
                data-aos-delay={(index + 1) * 100}
              >
                <img
                  src={post.image}
                  alt={`Instagram post ${index + 1}`}
                />
              </a>
            ))}
          </div>
        </div>
        <div className="instagram-bg d-flex align-items-center justify-content-center">
          <h3 className="fs-1" data-aos="fade-down" data-aos-delay="100">
            Instagram -
          </h3>
          <h3 className="fs-1" data-aos="fade-down" data-aos-delay="200">
            Instagram -
          </h3>
          <h3 className="fs-1" data-aos="fade-down" data-aos-delay="300">
            Instagram -
          </h3>
          <h3 className="fs-1" data-aos="fade-down" data-aos-delay="400">
            Instagram -
          </h3>
          <h3 className="fs-1" data-aos="fade-down" data-aos-delay="500">
            Instagram -
          </h3>
        </div>
      </div>
    </>
  );
};

export default InstagramPost;
